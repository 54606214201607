import { Link, NavLink, useMatch, useNavigate } from 'react-router-dom';

import './styles.css';

export default function AppBar() {
  const navigate = useNavigate();

  const isMatchFrequency = useMatch('/frequency');
  const isMatchItemDetail = useMatch('/item/:hash/:season/:week');

  function handler() {
    window.scrollTo(0, 0);
  }

  function handler_history(event) {
    if (isMatchItemDetail) {
      event.preventDefault();

      navigate(-1);
    } else {
      window.scrollTo(0, 0);
    }
  }

  const isHistoryActive = !isMatchFrequency && !isMatchItemDetail;

  return (
    <div id='app-nav' className='sticky-nav'>
      <NavLink to='/'>
        <div className='icon'>
          🗺️
        </div>
        <div className='text'>Map</div>
      </NavLink>
      <NavLink to='/shark'>
        <div className='icon'>
          🦈
        </div>
        <div className='text'>Shark</div>
      </NavLink>
      <NavLink to='/settings'>
        <div className='icon'>
          ⚙️
        </div>
        <div className='text'>Settings</div>
      </NavLink>
    </div>
  );
}
