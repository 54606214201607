import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import cx from 'classnames';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'intersection-observer';

import * as store from './store';

import './styles/Core.css';
import './styles/App.css';

import AppBar from './components/AppBar';

import Index from './views/Index';
import Shark from './views/Shark';
import Settings from './views/Settings';

export default function App() {
  const [viewport, setViewport] = useRecoilState(store.viewport);

  function updateViewport() {
    setViewport({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  useEffect(() => {
    window.addEventListener('resize', updateViewport);

    updateViewport();
  }, []);

  const FullScreenEnvironment = viewport.width < 768 || true;

  return (
    <BrowserRouter>
      <div
        className={cx('wrapper', {
          'reduced-motion': false,
          standalone: window.matchMedia('(display-mode: standalone)').matches,
          'full-screen': FullScreenEnvironment,
        })}>
        {/* <Header /> */}
        <div className='main'>
          <Routes>
            <Route path='/' element={<Index />} />
            <Route path='/shark' element={<Shark />} />
            <Route path='/settings' element={<Settings />} />
          </Routes>
          {/* <Footer /> */}
        </div>
        {FullScreenEnvironment && <AppBar />}
      </div>
    </BrowserRouter>
  );
}
